export const Cookies = [
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_2002.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_2005.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_2006.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_2008.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/173054039_594143238193158_2040975377958219973_n.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/178934875_603460760594739_4814452893853394338_n.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_0397.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_0282.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_0779.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/E9D1B374-111E-481F-A8DB-275EFCA73B14.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_1062.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_2676.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_3130.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_4913.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_20200710_222031.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/mario/IMG_1283.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_1674.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/4A06B6CB-E909-4255-911E-EF16772711A9.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/gallleta.jpg",
  "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_0344.jpg",
];

export const MixGallery = {
  cupcake: [
    "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/169242472_588994538708028_6769370066516075017_n.jpg",
    "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/161731090_574167740190708_3736347907473909557_n.jpg",
    "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/mix/pastelito/IMG_4277.jpg",
    "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/173054039_594143238193158_2040975377958219973_n.jpg",
  ],
  alfajores: [
    "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/170332119_588990785375070_6721124643433041470_n.jpg",
    " https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/170118469_588991548708327_2392349006352162783_n.jpg",
  ],
  others: [
    {
      url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/mix/gaznate/IMG_4130.jpg",
      position: "bottom",
    },
    {
      url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cookies/IMG_2002.jpg",
    },
    {
      url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/mix/IMG_20200706_175747.jpg",
    },
  ],
};
