import React from "react";
import styled from "styled-components";
import BannerComponent from "../components/BannerComponent";
import LayoutComponent from "../components/LayoutComponent";
import Stack from "../components/StackComponent";
import Section from "../components/SectionComponent";
import Title from "../components/TitleComponent";
import HorizontalLine from "../components/DividerLineComponent";
import { CustomCakes } from "../utils/cakes";
import CarouselReact from "../components/CarouselComponent";
import Text from "../components/TextComponent";
import { media } from "../styles/Mixins";
import { CgAsterisk } from "react-icons/cg";

export const Container = styled.div`
  position: relative;
`;

export const BodyContainer = styled.div`
  right: 0;
  left: 0;
  margin-top: 23px;
`;

export const CardSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 1rem;
`;

const Ul = styled.ul`
  font-family: "Architects Daughter", cursive;
  list-style-type: none;
  margin: auto;
  width: 300px;
  ${media.md`
  font-size: 25px;
    width: 550px;
  `}
`;

const Li = styled.li`
  display: flex;
  justify-content: center;
  svg {
    color: #df7a88;
    width: 20px;
    height: 25px;
  }
`;

const CakesView = () => {
  return (
    <Container>
      <BannerComponent
        cover="/banner.png"
        // cover="https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/1-1.jpeg"
        size="550px"
        position="center"
      />
      <BodyContainer>
        <LayoutComponent>
          <Stack>
            <Section>
              <Title>Pasteles</Title>
              <div style={{ margin: "auto" }}>
                <Text style={{ textAlign: "center", fontSize: 32 }}>
                  Déjate sorprender con nuestras decoraciones sorpresas
                  realizadas especialmente para tí, Te invitamos a disfrutar
                  nuestros deliciosos sabores ( Fresa, Chocolate, Cajeta,
                  Vainilla, nuez, Mantequilla ...) entre muchos otros.
                </Text>
              </div>

              <Title fontSize="40px">Opciones de combinaciones</Title>
              <Ul>
                <Li>
                  <CgAsterisk />
                  Crema de vainilla con fresas
                </Li>
                <Li>
                  <CgAsterisk />
                  Crema de chocolate blanco con fresas
                </Li>
                <Li>
                  <CgAsterisk />
                  Cajeta con nueces
                </Li>
                <Li>
                  <CgAsterisk />
                  Chocoflan
                </Li>
                <Li>
                  <CgAsterisk />
                  Flan de coco y crema de vainilla con fresas
                </Li>
                <Li>
                  <CgAsterisk />
                  Moka
                </Li>
                <Li>
                  <CgAsterisk />
                  Crema de Chocolate blanco con cerezas
                </Li>
                <Li>
                  <CgAsterisk />
                  Café
                </Li>
                <Li>
                  <CgAsterisk />
                  Nutella y café
                </Li>
                <Li>
                  <CgAsterisk />
                  Durazno con crema de cheesecake
                </Li>
                <Li>
                  <CgAsterisk />
                  Crema de coco y nueces
                </Li>
                <Li>
                  <CgAsterisk />
                  Crema de Rompope
                </Li>
                <Li>
                  <CgAsterisk />
                  Piña colada
                </Li>
              </Ul>
              <Text style={{ textAlign: "center", padding: "0 4rem" }}>
                pregunta por alguna combinación especial y te sorprenderemos con
                nuestros sabores
              </Text>
            </Section>
            <HorizontalLine />

            <Section>
              <Title>Nuestros Trabajos</Title>
              <Text style={{ textAlign: "center" }}>
                Te dejamos algunos de nuestros trabajos.
              </Text>
              <CarouselReact images={CustomCakes} />
            </Section>
            <HorizontalLine />
            <Section>
              <Title>Service order</Title>
              <Text style={{ textAlign: "center" }}>
                To place an order, please send an email
              </Text>
            </Section>
          </Stack>
        </LayoutComponent>
      </BodyContainer>
    </Container>
  );
};

export default CakesView;
