export const DailyCake = [
  {
    image:
      "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/380b37da-650b-47fc-8135-334f9c5af8ca.jpg",
    name: "Vainilla Cake",
    description:
      "Relleno de fresas y crema de cheesecake, capas iguales o combinadas (cajeta con nuez o Vainilla).",
  },
  {
    image:
      "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_4399.jpg",
    name: "Chocolate Cake",
    description:
      "Relleno de fresas y crema de chocolate blanco, capas iguales o combinadas (cajeta con nuez o vanilla).",
    position: "bottom",
  },
];

export const CustomCakes = [
  { url: "https://pepez-bakery-bucket.s3.us-west-1.amazonaws.com/images/cakes/IMG_3076.jpg"},
  { url: "https://pepez-bakery-bucket.s3.us-west-1.amazonaws.com/images/cakes/IMG_2799.jpg"},
  { url: "https://pepez-bakery-bucket.s3.us-west-1.amazonaws.com/images/cakes/IMG_3056.jpg"},
  { url: "https://pepez-bakery-bucket.s3.us-west-1.amazonaws.com/images/cakes/IMG_3118.jpg"},
  { url: "https://pepez-bakery-bucket.s3.us-west-1.amazonaws.com/images/cakes/IMG_2664.jpg"},
  { url: "https://pepez-bakery-bucket.s3.us-west-1.amazonaws.com/images/cakes/IMG_2850.jpg"},
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/182609623_609264810014334_2164441974107995220_n.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/184773251_613293279611487_7485976081301826024_n.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/182690802_609262896681192_4678948079890175903_n.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_1532.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/175341301_598710721069743_1492200018431380174_n.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/178799205_603450377262444_3845952741057737217_n.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/164359236.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/164489497_578293056444843.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/168462610_586195155654633.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_1155.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_1174.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/480faecb-7095-43f6-adca-cd49f663180a.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_0143-min.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_0497.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_0570.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_0673.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_1003.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_0243.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_0442.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/161680096_574184946855654_2484837928181890297_n.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_0320.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_5258-min.JPG",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_0158-min.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_5320-min.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/e25e8b02-bb2f-4969-b57d-19da70f1e047.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_4606.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_20200920_005648.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/cake-2020-09-18-09.05.33.jpeg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/380b37da-650b-47fc-8135-334f9c5af8ca.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_4399.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_20200709_214927.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_4694.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/pastel2/IMG_4685.jpg",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_4902.jpg",
    position: "bottom",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_4839.jpg",
    position: "bottom",
  },
  {
    url: "https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/images/cakes/IMG_4392.jpg",
    position: "bottom",
  },
];
