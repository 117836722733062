import React from "react";
import styled from "styled-components";
import TopHeader from "../components/TopHeaderComponent";
import Header from "../components/NavBarComponent";
import { MENU } from "../utils/constants";
import { withRouter } from "react-router-dom";

const HeaderContainer = styled.div`
  background: #fff;
  color: #7a7a7a;
`;

const HeaderComponent = ({ history, pathUrl, isHome, setLang, lang }) => {
  const goTo = (path) => history.push(path);
  return (
    <HeaderContainer>
      <TopHeader setLang={setLang} />
      <Header
        options={MENU}
        goTo={goTo}
        pathUrl={pathUrl}
        isHome={isHome}
        lang={lang}
      />
    </HeaderContainer>
  );
};

export default withRouter(HeaderComponent);
