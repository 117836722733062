import i18next from "i18next";

i18next.init({
  interpolation: {
    // React already does escaping
    escapeValue: false,
  },
  lng: "en", // 'en' | 'es'
  // Using simple hardcoded resources for simple example
  resources: {
    en: {
      translation: {
        "see-our-cakes": "See our Cakes",
        about: "About us",
        crispness: "Crispness",
        flavor: "Flavor",
        quality: "Quality",
        services: "Services",
        contact: "Contact",
        home: "Home",
        cakes: "Cakes",
        cookies: "Cookies",
        desserts: "Desserts",
        "contact-text":
          "We appreciate you make your orders in advance, Send an email for Orders and inquieres",
        "contact-text-2": "we will gladly provide you with the service.",
        "services-text":
          "Pepez offers you cakes, cookies, alfajores, crepepez, cup cakes, cheesecake, Cuban peanut bars, among others.",
        "line-home-1":
          "For a long time we were fond of making desserts and little by little we were entering the sweet path of artisan confectionery, until we decided to create",
        "line-home-2":
          "taking into account the characteristics, tastes and needs of the people, at the time of select an artisan dessert with our 3 virtues.",
        "change-lang": "Language to:",
      },
    },
    es: {
      translation: {
        home: "Inicio",
        cakes: "Pasteles",
        cookies: "Galletas",
        desserts: "Postres",
        "change-lang": "Idioma a:",
        "see-our-cakes": "Ver Nuestros Pasteles",
        crispness: "Frescura",
        flavor: "Sabor",
        quality: "Calidad",
        services: "Servicios",
        contact: "Contacto",
        "contact-text":
          "Agradecemos realices tus pedidos con anticipación, puedes enviar un email a",
        "contact-text-2": "con gusto te brindamos el servicio.",
        "services-text":
          "Pepez te ofrece pasteles, galletas, alfajores, crepepez, cup cakes, cheesecake, barras de cacahuate Cubano, entre otros",

        "line-home-1":
          "Durante mucho tiempo fuimos aficionados a la elaboracion de postres y poco a poco nos fuimos adentrando en el dulce camino de la resposteria artesanal, hasta que decidimos crear a",
        "line-home-2":
          "tomando en cuenta las caracteristicas, gustos y necesidades de las personas, a la hora de seleccionar un postre artesanal con nuestras 3 virtudes.",
        about: "Nosotros",
        age: { label: "Años" },
        name: { label: "Nombre" },
      },
    },
  },
});

export default i18next;
