import styled from 'styled-components';
 
const Stack = styled.div`
  display: flex;
  flex-direction: column;

  &&& > * + * {
    margin-top: ${({ gutter = 1 }) => `${gutter * 8}px`};
  }
`;

export default Stack;
