import styled from 'styled-components';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  text-align: center;
  color: #4a4a4a;
  background-color: ${props => props.bColor ? props.bColor : 'none'};
`;

export default Section;