import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Button from "../components/ButtonComponent";
import LayoutComponent from "../components/LayoutComponent";
import Stack from "../components/StackComponent";
import { media } from "../styles/Mixins";
import Overlay from "../components/OverlayComponent";
import Text from "../components/TextComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import i18n from "../utils/i18n";

const Wrapper = styled.div`
  position: relative;
  font-family: "Reenie Beanie", cursive;
  height: 100%;
`;

const ContainerBanner = styled.div`
  width: 100%;
  height: calc(100% - 1px);
`;

const DisplayBottom = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-flow: row;
  flex-grow: 1;
`;

const Cover = styled.div`
  height: ${(props) => props.size};
  width: 100%;
  background-image: url("/cover_mobile.png");
  background-position: center center;
  background-size: cover;
  background-color: #cccccc;
  background-repeat: no-repeat;
  position: relative;

  ${media.md`
    background-image: url("/cover_desktop.png");
    height: 600px;
    background-position: center;
    width: 100%;
  `}
  ${media.xl`
    height: 890px;
   `}
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 2rem;
  z-index: 10;
  width: 100%;
  text-align: center;
  ${media.md`
    display: none;
  `}
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 3rem;
  ${media.md`
   flex-direction: row;
 `}
`;

const Cell = styled(Box)`
  justify-content: space-around;
`;

const Row = styled.div`
  margin: 0;
  width: 100%;
  padding: 4rem 0;
`;
const ContainerRow = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

const Container = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isReverse ? "column-reverse" : "column")};

  ${media.md`
    flex-direction: row;
  `}
`;

const ColumnMD = styled.div`
  ${media.md`
    width: 50%;
    float: left;
  `}
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  text-align: center;
  padding: 0 5px;
  min-height: 400px;
`;

const ImageCover = styled.div`
  display: flex;
  background-size: cover;
  margin: auto;
  background-image: url(${(props) => props.backgroundImage});
  transform duration-300 ease-in-out;
  &:hover {
    transform: scale(1.2); 
  }
`;

const ImageService = styled(ImageCover)`
  width: 295px;
  height: 200px;
  ${media.md`
    height: 400px;
    width: 570px;
  `}
`;

const ImageContact = styled(ImageCover)`
  ${media.md`
    margin-top: 8rem;
    width: 450px;
    height: 260px;
  `}
`;

const Title = styled.h3`
  font-size: ${(props) => props.size};
  color: ${({ color = "black" }) => color};
  text-align: center;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 45px;
  color: #fff;
  margin-bottom: 15px;
  ${media.md`
    margin: 0;
  `}
`;

const ItemIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 55px;
  transform duration-300 ease-in-out;
  &:hover {
    transform: scale(1.2); 
  }
`;

const Icon = styled.i`
  font-size: ${({ size = "4em" }) => size};
  font-weight: bold;
  color: ${({ color = "#fff" }) => color};
`;

const ItemText = styled.span`
  margin-top: 1rem;
  text-transform: initial;
  font-size: 44px;
  line-height: initial;
  font-weight: 400;
  text-align: center;
  color: #fff;
`;

const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #000;
  span {
    margin-left: 4px;
  }
`;

const Glyphicons = ({ isIcon = false, color, text, icon, width, children }) => {
  return (
    <Item>
      <ItemIcon>
        {children && children}
        {!children && isIcon ? (
          <Icon className="material-icons" color={color}>
            {icon}
          </Icon>
        ) : (
          <img src={icon} style={{ width: width }} alt="" />
        )}
      </ItemIcon>
      <ItemText>{text}</ItemText>
    </Item>
  );
};

const HomeView = ({ history, lang }) => {
  return (
    <Wrapper>
      <ContainerBanner>
        <BannerContainer>
          <Cover size="650px">
            <Overlay />
            <ButtonContainer>
              <Button
                color="cornflowerblue"
                bg="#f5f5f5"
                onClick={() => history.push("/cakes")}
              >
                {i18n.t("see-our-cakes", { lng: lang })}
              </Button>
            </ButtonContainer>
            <DisplayBottom>
              <a
                href="https://www.freepik.com"
                style={{ color: "#fff", fontSize: 11 }}
              >
                images by freepik.com
              </a>
            </DisplayBottom>
          </Cover>
        </BannerContainer>
      </ContainerBanner>
      <Box style={{ background: "rgb(223, 122, 136)" }}>
        <Row>
          <ContainerRow>
            <LayoutComponent>
              <Title color="#fff" size="50px">
                {i18n.t("about", { lng: lang })}
              </Title>
              <Text color="#fff">
                {i18n.t("line-home-1", { lng: lang })} <strong>Pepez</strong>,{" "}
                {i18n.t("line-home-2", { lng: lang })}
              </Text>
              <Stack>
                <Cell>
                  <Glyphicons
                    color="#B9E2E8"
                    text={i18n.t("crispness", { lng: lang })}
                    width="56px"
                    icon="https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/assets/baking.png"
                  />
                  <Glyphicons
                    color="#B9E2E8"
                    text={i18n.t("flavor", { lng: lang })}
                    width="56px"
                    icon="https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/assets/icons8-ok-hand-96.png"
                  />
                  <Glyphicons
                    color="#ffff66"
                    text={i18n.t("quality", { lng: lang })}
                    width="56px"
                    icon="https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/assets/heart.png"
                  />
                </Cell>
              </Stack>
            </LayoutComponent>
          </ContainerRow>
        </Row>
      </Box>
      <Box style={{ backgroundColor: "#fff" }}>
        <Row>
          <Container isReverse>
            <ColumnMD>
              <ImageService backgroundImage="https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/assets/3305765.jpg" />
            </ColumnMD>
            <ColumnMD>
              <FlexContainer>
                <Title size="45px">{i18n.t("services", { lng: lang })}</Title>
                <Text>{i18n.t("services-text", { lng: lang })}..</Text>
              </FlexContainer>
            </ColumnMD>
          </Container>
        </Row>
      </Box>
      <Box style={{ backgroundColor: "#fff" }}>
        <Row>
          <Container isReverse>
            <ColumnMD>
              <FlexContainer>
                <Title size="45px">{i18n.t("contact", { lng: lang })}</Title>
                <Text>
                  {i18n.t("contact-text", { lng: lang })}:{" "}
                  <Link href="mailto:info@pepezbakery.com">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span>info@pepezbakery.com</span>
                  </Link>
                </Text>
                <Text> {i18n.t("contact-text-2", { lng: lang })} </Text>
              </FlexContainer>
            </ColumnMD>
            <ColumnMD>
              <ImageContact backgroundImage="https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/assets/2451377.png" />
            </ColumnMD>
          </Container>
        </Row>
      </Box>
    </Wrapper>
  );
};

export default withRouter(HomeView);
