import styled from 'styled-components';

const Title = styled.h4`
  font-family: 'Reenie Beanie',cursive;
  font-size: ${ ({fontSize = '50px' }) => fontSize};
  text-align: center;
  color: #4a4a4a;
  margin: 1rem 0;
`;

export default Title;