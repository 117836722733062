import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { media } from "../styles/Mixins";
import { Link } from "../components/TopHeaderComponent";
import LayoutComponent from "../components/LayoutComponent";
import { GiHamburgerMenu } from "react-icons/gi";
import i18n from "../utils/i18n";

const COLOR_DARK_GREEN = "#0a717b";

const Header = styled.header`
  right: 0;
  left: 0;
  background-color: ${(props) =>
    props.isTransparent ? "rgba(0,0,0,0.15)" : "#fff"};
  position: fixed;
  z-index: 11;
  margin-top: 34px;
`;

const Wrapper = styled.div`
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  transition: 0.4s linear;
  z-index: 10;
  ${media.md`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    justify-content: space-evenly;
  `}
`;

const ContainerLogo = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 95px;
  ${media.md`
    display: flex;
    flex-direction: row;
    align-items: center
    justify-content: space-between;
    width: 50%;
  `}
`;

const Brand = styled.a`
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  font-weight: 900;
  font-size: 32px;
  text-transform: uppercase;
  color: #000000;
  margin: 0 auto;
  span {
    color: ${COLOR_DARK_GREEN};
  }
`;

const Logo = styled.div`
  background-image: url(${(props) => props.src});
  min-height: 100%;
  background-position: center;
  background-size: cover;
  width: 85px;
  height: 105px;
  ${media.md`  
    width: 85px;
    height: 105px;
  `}
`;

const Item = styled.div`
  background-color: #fff;
  width: 100%;
  top: 80px;
  box-shadow: 0 1px 0 0 #ffd8e7;
  text-transform: uppercase;
  display: ${(props) => (props.showMobileMenu ? "block" : "none")};
  ${media.md`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 75px;
    margin: 0;
    box-shadow: none;
    width: 50%;
    background: none;
  `}
`;

const Option = styled.a`
  display: block;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 18px;
  box-shadow: 0 1px 0 0 #f7f4ff;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  :hover {
    border-bottom: 2px solid #df7a88;
  }

  ${media.md`
    color: ${(props) => props.colorOption};
    background-color: transparent;
    border-radius: 2px;
    margin-left: 14px;
    box-shadow: none;
    ${(props) =>
      props.selected &&
      css`
        color: #df7a88;
      `};
  `}
`;

const CustomLink = styled(Link)`
  width: 60px;
  height: 67px;
  color: ${(props) => (props.isTransparentNav ? "#fff" : "#000")};
  display: flex;
  ${media.md`
    display: none;
  `}
`;

const NavBarComponent = ({
  lang,
  isHome = false,
  goTo,
  options,
  pathUrl = "/",
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [colorOption, setColorOption] = useState("#4A4A4A");
  const [isTransparentNav, setIsTransparentNav] = useState(true);
  const navBarRef = useRef(null);

  const handleScroll = () => {
    if (navBarRef.current) {
      const heightNavbar = navBarRef.current.getBoundingClientRect().height;
      const afterLimit =
        document.body.scrollTop + heightNavbar > 500 ||
        document.documentElement.scrollTop + heightNavbar > 500;
      setIsTransparentNav(!afterLimit);
    }
  };

  useEffect(() => {
    if (!isTransparentNav) {
      setColorOption("#4a4a4a");
    }

    if (isTransparentNav) {
      setColorOption("#fff");
    }
  }, [isTransparentNav]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const goToPage = (slug) => {
    goTo(slug);
    setShowMenu(false);
  };

  return (
    <Header ref={navBarRef} isTransparent={isTransparentNav}>
      <LayoutComponent>
        <Wrapper>
          <ContainerLogo>
            {true && (
              <Brand href="/">
                <Logo src="https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/assets/logo-no-background.png" />
              </Brand>
            )}
            <CustomLink
              onClick={() => setShowMenu((prevState) => !prevState)}
              isTransparentNav={isTransparentNav}
            >
              <GiHamburgerMenu />
            </CustomLink>
          </ContainerLogo>
          <Item showMobileMenu={showMenu}>
            {options.map((option, index) => (
              <div key={option.title}>
                <Option
                  colorOption={colorOption}
                  selected={pathUrl === option.slug}
                  key={option.title.length + index}
                  border={option.border}
                  onClick={() => goToPage(option.slug)}
                >
                  {i18n.t(option.title, { lng: lang })}
                </Option>
              </div>
            ))}
          </Item>
        </Wrapper>
      </LayoutComponent>
    </Header>
  );
};

export default NavBarComponent;
