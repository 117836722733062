import React, { Suspense } from "react";
import styled from "styled-components";
import { useImage } from "react-image";
import LoaderComponent from "./LoaderComponent";

const Photo = styled.div`
  background-image: url(${(props) => props.url});
  height: 100%;
  background-size: cover;
  background-color: #cccccc;
  background-repeat: no-repeat;
  background-position: ${(props) =>
    props.position ? props.position : "center"};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const MyImageComponent = ({ url, position = null }) => {
  const { src } = useImage({
    srcList: url,
  });
  return <Photo url={src} position={position} />;
};

const CardLoaderComponent = ({ url, position }) => {
  return (
    <Suspense fallback={<LoaderComponent />}>
      <MyImageComponent url={url} position={position} />
    </Suspense>
  );
};

export default CardLoaderComponent;
