import styled from 'styled-components';
import { media } from '../styles/Mixins';

const Text = styled.p`
  font-family: 'Shadows Into Light', cursive;
  text-align: justify;
  font-size: 20px;
  padding: 10px;
  color: ${({color = '#4a4a4a'}) => color};
  ${media.md`
    padding: 10px 30px;
    font-size: 30px;
  `}
`;

export default Text;