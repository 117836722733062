import React from "react";
import styled from "styled-components";

const Button = styled.button`
  border-radius: 2px;
  height: 40px;
  border: 0;
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: 0;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s linear;
  letter-spacing: 0.5px;
  min-width: 100px;
  padding: 5px 18px;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  font-size: 14px;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif !important;
  text-transform: uppercase !important;

  :hover {
    &:after {
      content: " 🍰";
    }
    // background-color: #c86d7a;
  }
`;

const ButtonComponent = ({
  children,
  onClick,
  color = "#fff",
  bg = "#df7a88",
}) => {
  return (
    <Button onClick={onClick} bg={bg} color={color}>
      {children}
    </Button>
  );
};

export default ButtonComponent;
