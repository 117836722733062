import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { media } from "../styles/Mixins";
import { FaEnvelope, FaInstagram } from "react-icons/fa";
import { AiFillFacebook } from "react-icons/ai";
import i18n from "../utils/i18n";

const Nav = styled.nav`
  top: 0;
  right: 0;
  left: 0;
  background-color: #df7a88;
  position: fixed;
  z-index: 11;
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  ${media.xss`
    width: 540px;
    max-width: 100%;
  `}

  ${media.sm`
    width: 720px;
    max-width: 100%;
  `}

  ${media.md`
    witdh: 960px;
    max-width: 100%;
  `}

  ${media.xl`
    width: 1140px;
    max-width: 100%;
  `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 15px;
  margin-right: -15px;
  margin-left: -15px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  a:not(:first-of-type) {
    padding-left: 10px;
  }
`;

const SecondaryColumn = styled(Column)`
  justify-content: flex-end;
  ul {
    list-style: none;
    padding-left: 0;
  }

  a {
    background-color: transparent;
  }
`;

export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  color: ${(props) => (props.color ? props.color : "#fff")};

  i {
    font-size: 14px;
    ${media.md`
      font-size: 15px;    
    `}
  }

  .material-icons {
    font-family: Material Icons;
    font-weight: 400;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-feature-settings: "liga";
    font-feature-settings: "liga";
  }
`;

export const TextLink = styled.span`
  padding: 0 2px;
  font-size: 13px;

  ${media.md`
    padding: 0 5px;
    font-size: 15px;
  `}
`;

export const TextLinkEmail = styled(TextLink)`
  font-weight: 200;
  font-size: 14px !important;
  display: none;

  ${media.md`
    display: block;
  `}
`;

const Social = styled.div`
  padding-right: 1.5rem;
`;

const Facebook = styled(AiFillFacebook)`
  width: 1.25rem;
  height: 1.25rem;
`;

const Instagram = styled(FaInstagram)`
  width: 1.25rem;
  height: 1.25rem;
`;

const Envelope = styled(FaEnvelope)`
  width: 1.25rem;
  height: 1.25rem;
`;

const LanguageIcon = styled.img`
  width: 33px;
  height: 19px;
  object-fit: scale-down;
`;

const TopHeaderComponent = ({ setLang }) => {
  const [defaultLang, setDefaultLang] = useState(
    localStorage.getItem("pepezLang") || "en"
  );

  function onLanguageChanged(lang) {
    setDefaultLang(lang);
    localStorage.setItem("pepezLang", lang);
  }

  useEffect(() => {
    if (
      localStorage.getItem("pepezLang") === null ||
      localStorage.getItem("pepezLang") === undefined
    ) {
      localStorage.setItem("pepezLang", defaultLang);
    }
  }, [defaultLang]);

  useEffect(() => {
    i18n.on("languageChanged", onLanguageChanged);
    return () => i18n.off("languageChanged", onLanguageChanged);
  }, []);

  useEffect(() => {
    setLang(defaultLang);
  }, [defaultLang, setLang]);

  return (
    <Nav className="custom-header bg-color-pink">
      <Container>
        <Row>
          <Column>
            <span style={{ color: "#fff", fontSize: 16 }}>
              {i18n.t("change-lang", { lng: defaultLang })}
            </span>
            {defaultLang === "en" ? (
              <LanguageIcon
                src="/mx_flag.png"
                onClick={() => onLanguageChanged("es")}
              />
            ) : (
              <LanguageIcon
                src="/us_flag.png"
                onClick={() => onLanguageChanged("en")}
              />
            )}
          </Column>
          <div className="clearfix"></div>
          <SecondaryColumn>
            <Social>
              <Link
                href="https://www.facebook.com/thepepezbakery"
                target="_blank"
                rel="PepezBakery"
              >
                <Facebook style={{ color: "#fff" }} />
              </Link>
            </Social>
            <Social>
              <Link
                href="https://www.instagram.com/pepezbakery"
                target="_blank"
              >
                <Instagram style={{ color: "#fff" }} />
              </Link>
            </Social>
            <Social>
              <Link href="mailto:info@pepezbakery.com">
                <Envelope />
                <TextLinkEmail>info@pepezbakery.com</TextLinkEmail>
              </Link>
            </Social>
          </SecondaryColumn>
        </Row>
      </Container>
    </Nav>
  );
};

export default TopHeaderComponent;
