import styled from 'styled-components';
import { media } from '../styles/Mixins';

const LayoutComponent = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  ${media.xss`
    width: 540px;
    max-width: 100%;
  `}

  ${media.sm`
    width: 720px;
    max-width: 100%;
  `}

  ${media.md`
    witdh: 960px;
    max-width: 100%;
  `}

  ${media.xl`
    width: 1140px;
    max-width: 100%;
  `}
`;

export default LayoutComponent;
