import React from 'react';
import styled from 'styled-components';
import { media } from '../styles/Mixins';

const BannerContainer = styled.div`
  display: flex;
  flex-flow: row;
  flex-grow: 1;
`;

const Banner = styled.div`
  height: ${props => props.size};
  width: 100%;
  background-image: url(${props => props.cover});
  background-position: ${props => props.position};
  background-size: cover;
  background-color: #cccccc;
  background-repeat: no-repeat;
  position: relative;

  ${media.md`
    height: ${props => props.isFull ? 'calc( 992px - 1px)' : props.size};
    width: 100%;
  `}
`;

const BannerComponent = ({children, cover, size = "300px",  position = 'center', isFull = false}) => {
  return (
    <BannerContainer>
      <Banner
        cover={cover}
        size={size}
        position={position}
        isFull={isFull}
      >
        {children}
      </Banner>
    </BannerContainer>
  )
}

export default BannerComponent;