import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import Home from "../view/HomeView";
import Cakes /*, { Container }*/ from "../view/CakesView";
import MixView from "../view/MixView";
import Cookies from "../view/CookiesView";
import HeaderComponent from "../components/HeaderComponent";
import { GLOBALSTYLE } from "../utils/constants";

import styled from "styled-components";

const Container = styled.div`
  font-family: "Architects Daughter", cursive;
  width: 100%;
`;

const Routes = withRouter(({ history }) => {
  const {
    location: { pathname },
  } = history;
  const [selected, setSelected] = useState("cake");
  const [lang, setLang] = useState("en");

  const [isHome, setIsHome] = useState(() => pathname === "/");
  useEffect(() => {
    setIsHome(pathname === "/");
    setSelected(() => pathname);
  }, [pathname]);

  return (
    <>
      <HeaderComponent pathUrl={selected} isHome={isHome} setLang={setLang} lang={lang} />
      <Switch>
        <Route
          exact
          path="/desserts"
          render={(props) => <MixView {...props} lang={lang} />}
        />
        <Route exact path="/cookies" component={Cookies} />
        <Route exact path="/cakes" component={Cakes} />
        <Route
          exact
          path="/"
          render={(props) => <Home {...props} lang={lang} />}
        />
      </Switch>
    </>
  );
});

const App = () => {
  return (
    <Container>
      <Router>
        <Routes />
      </Router>
      <GLOBALSTYLE />
    </Container>
  );
};

export default App;
