// @flow
import React, { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { media } from "../styles/Mixins";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import "react-lazy-load-image-component/src/effects/blur.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardLoaderComponent from "./CardLoaderComponent";

const WrapperCarousel = styled.div`
  margin: auto;
  width: 100%;
  padding: 10px;
  text-align: initial;
  ${media.md`
    width: 95%;
  `}

  ${media.xl`
    width: 70%;
  `}
`;

const CounterPhotos = styled.div`
  display: table;
  margin: 0 auto;
  text-align: center;
  padding: 6px 25px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.58);
  color: #ffffff;
  font-size: 14px;
  letter-spacing: -0.22px;
  line-height: 17px;
`;

const Carousel = styled.div`
  height: 80vh;
  width: 100%;
  margin: 39px 0 17px 0;
`;

const Arrow = styled.button`
  background: none;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  position: relative;
`;

const ArrowWrapper = styled.div`
  ${(props) => props.type}: 0;
  position: absolute;
  top: 0;
  width: 35px;
  height: 100%;
  z-index: 10;
  background: none;
  ${Arrow} {
    background-color: #4a4a4a;
    opacity: 0.5;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 27px !important;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 16px;
  }

  ${media.md`
    width: 74px;
  `}
`;

const StyledSlider = styled(Slider)`
  height: 100%;
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide div {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    ${media.md`
      width: 93.4%;  
    `}
  }
`;

const NextArrow = ({ onClick }) => (
  <ArrowWrapper type="right" onClick={onClick}>
    <Arrow />
    <FontAwesomeIcon icon={faChevronRight} />
  </ArrowWrapper>
);

const PrevArrow = ({ onClick }) => (
  <ArrowWrapper type="left" onClick={onClick}>
    <Arrow onClick={onClick} />
    <FontAwesomeIcon icon={faChevronLeft} />
  </ArrowWrapper>
);

const CarouselComponent = ({ images }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1);

  const updateCurrentSlide = (current) => setCurrentSlideIndex(current + 1);

  const sliderSettings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    lazyLoad: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (index, after) => updateCurrentSlide(after),
  };

  return (
    <WrapperCarousel>
      <Carousel>
        <StyledSlider {...sliderSettings}>
          {images.map((image, index) => (
            <CardLoaderComponent
              key={index}
              url={image.url}
              position={image.position}
            />
          ))}
        </StyledSlider>
      </Carousel>
      <CounterPhotos>
        {currentSlideIndex} de {images.length}
      </CounterPhotos>
    </WrapperCarousel>
  );
};

export default CarouselComponent;
