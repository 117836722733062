import React from "react";
import styled from "styled-components";

const AnimationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`;

const LoaderComponent = () => (
  <AnimationContainer>
    <img src="/cake-loading.png" style={{ width: 200, height: 200 }} alt="" />
  </AnimationContainer>
);

export default LoaderComponent;
