import styled, {css} from 'styled-components';

export const clearfix = `
  &::after{
    content: "";
    display: table;
    clear: both;
  }
`;

export const media = {
  xss: (...args) => css`
    @media(min-width: 576px) {
      ${css(...args)}
    }
  `,
  xs: (...args) => css`
    @media(max-width: 767px) {
      ${css(...args)}
    }
  `,
  sm: (...args) => css`
    @media(min-width: 768px) {
      ${css(...args)}
    }
  `,
  md: (...args) => css`
    @media(min-width: 992px) {
      ${css(...args)}
    }
  `,
  xl: (...args) => css`
    @media(min-width: 1200px) {
      ${css(...args)}
    }
  `
};

export function svgColor(svg, fillColor) {
  if(svg && fillColor) {
    let fill = css`
      & > path {
        fill: ${fillColor}
      }
    `;
    return styled(svg)`
      ${fill}
    `;
  }
  return svg || styled.svg``;
}