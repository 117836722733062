import React, { Suspense } from "react";
import styled from "styled-components";
import { useImage } from "react-image";
import LoaderComponent from "./LoaderComponent";
const CakeCard = styled.div`
  width: 320px;
  padding: 0px;
  margin: 5px;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  transform duration-300 ease-in-out;
  &:hover {
    transform: scale(1.1); 
  }
`;

const CardImage = styled.div`
  width: 100%;
  height: 320px;
  background-image: url(${(props) => props.url});
  background-position: ${(props) =>
    props.position ? props.position : "center"};
  background-size: cover;
  background-color: #cccccc;
  background-repeat: no-repeat;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.375rem;
`;

const CardText = styled.h4`
  font-size: 17px;
  margin: 8px 0;
`;

const CardDescription = styled.span``;

const MyImageComponent = ({ url, position, witdh, height }) => {
  const { src } = useImage({
    srcList: url,
  });
  return (
    <CardImage url={src} position={position} witdh={witdh} height={height} />
  );
};

const CardComponent = ({ urlImage, cake, position }) => {
  return (
    <CakeCard>
      <Suspense fallback={<LoaderComponent />}>
        <MyImageComponent url={urlImage} position={position} />
      </Suspense>
      <CardText>{cake && cake.name}</CardText>
      <CardDescription>{cake && cake.description}</CardDescription>
    </CakeCard>
  );
};

export default CardComponent;
