import React from 'react';
import BannerComponent from '../components/BannerComponent';
import LayoutComponent from '../components/LayoutComponent';
import Section from '../components/SectionComponent';
import Title from '../components/TitleComponent';
import Text from '../components/TextComponent';
import Stack from '../components/StackComponent';
import {
  Container,
  BodyContainer,
  CardSection
} from './CakesView';
import { Cookies } from '../utils/cookies';
import CarouselReact from '../components/CarouselComponent';

const CookiesView = () => {
  return (
    <Container>
      <BannerComponent
        cover="https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/assets/cookies.jpeg"
        size="500px"
        position="left center"
      />
      <BodyContainer>
        <LayoutComponent>
          <Stack>
            <Section>
              <Title>
                Cookies
              </Title>
              <Text>
                Las galletas decoradas son una buena opción, para una fecha conmemorativa: Birthay, Baby, Super Bowl, Valentine Day, Rodeo, 
                Mardi Grass, St Patrick Day, Easter, 5 de Mayo, Teacher appreciation, Mother Day, Graduation, Father Day, Prade, July 4, 
                Hallowen, Pink Ribbon, Boss’ Day, Thanksgivings, Chrismas, New Year.
              </Text>
              <CardSection>
                <CarouselReact images={Cookies.map(cookie => ({url: cookie}))} />
              </CardSection>
            </Section>
          </Stack>
        </LayoutComponent>
      </BodyContainer>
    </Container>
  )
}

export default CookiesView;