import { createGlobalStyle } from 'styled-components';

export const GLOBALSTYLE = createGlobalStyle`
  body {
    font-family: "Open Sans",Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    padding: 0;
    margin: 0;
    background-color: #F5F5F5;
  }

  html {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: 0 0;
    font-size: 100%;
    vertical-align: baseline;
  }

`;

export const MENU = [
  { title: 'home', slug: '/' },
  // { title: 'Menu', slug: '/menu' },
  { title: 'cakes', slug: '/cakes' },
  { title: 'cookies', slug: '/cookies' },
  { title: 'desserts', slug: '/desserts'}
];
