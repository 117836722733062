import React from "react";
import BannerComponent from "../components/BannerComponent";
import LayoutComponent from "../components/LayoutComponent";
import Section from "../components/SectionComponent";
import Title from "../components/TitleComponent";
import CardComponent from "../components/CardComponent";
import Stack from "../components/StackComponent";
import HorizontalLine from "../components/DividerLineComponent";

import { Container, BodyContainer, CardSection } from "./CakesView";
import { MixGallery } from "../utils/cookies";

const CookiesView = () => {
  const { cupcake, alfajores, others } = MixGallery;
  return (
    <Container>
      <BannerComponent
        cover="https://pepez-bakery-bucket.s3-us-west-1.amazonaws.com/assets/macarons.jpeg"
        size="500px"
        position="right center"
      />
      <BodyContainer>
        <LayoutComponent>
          <Stack>
            <Section>
              <Title>Desserts</Title>
              <CardSection>
                {cupcake.map((photo) => (
                  <CardComponent
                    urlImage={photo}
                    position={photo.position && photo.position}
                  />
                ))}
              </CardSection>
            </Section>
            <HorizontalLine />
            <Section>
              <CardSection>
                {alfajores.map((photo) => (
                  <CardComponent urlImage={photo} position="bottom" />
                ))}
              </CardSection>
            </Section>
            <HorizontalLine />
            <Section>
              <CardSection>
                {others.map((photo) => (
                  <CardComponent
                    urlImage={photo.url}
                    cake={photo}
                    position={photo.position}
                  />
                ))}
              </CardSection>
            </Section>
          </Stack>
        </LayoutComponent>
      </BodyContainer>
    </Container>
  );
};

export default CookiesView;
